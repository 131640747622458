<template>
<div class="background">
  <div class="card">
    <div class="card_title">
      <div class="name_lable">
        <div class="name">
          {{ this.info.caseName }}
        </div>
        <div class="lable">
          <div>{{ this.info.typeFirst }}</div>
          <div>{{ this.info.typeSecond }}</div>
        </div>

      </div>
      <div class="popularity">
        <div></div>
        <i></i>
        <div></div>
        <i></i>
        <div></div>

      </div>
    </div>
    <div class="content" v-html="this.info.details">

    </div>

    <div class="end">
      <div class="statement">
        声明：本作品版权归作者所有， 禁止匿名转载和个人使用， 任何商业用途均需联系作者和平台
      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
  name: "CaseDetail",
  data(){
    return {
      caseId: {
        caseId: 126,
      },
      info:'',

    }
  },
  mounted() {
    this.getInfo();
  },
  methods:{
    getInfo(){
      this.caseId.caseId = parseInt(JSON.parse(this.$route.query.caseId));
      this.$http({
        method: 'post',
        url: '/getCaseMessage',
        data: this.caseId
      }).then(res => {
        console.log(res);
        this.info = res.data;
      }
      )}
  }
}
</script>

<style scoped>
.background{
  width: 19.2rem;
  background-color: #fdf9f8;
  padding-top: .52rem;
}

.breadcrumb div:nth-child(1){
  float: left;
  color: #212121;
}

.breadcrumb div:nth-child(2){
  float: left;
  margin-right: .05rem;
  color: #9c9c9c;
}

.breadcrumb div:nth-child(3){
  float: left;
  margin-right: .05rem;
  color: #9c9c9c;

}

.breadcrumb div:nth-child(4){
  float: left;
  color: #ec414d;
}

.card{
  width: 15.6rem;
  background-color: #ffffff;
  border-radius: .05rem;
  padding-top: .63rem;
  margin: 0 auto .5rem;

}

.card .card_title {
  width: 14.6rem;
  height: .96rem;
  border-bottom: .01rem solid #c5c5c5;
  /*line-height: .96rem;*/
  margin: 0 auto .4rem;
}

.card .card_title .name_lable{
  width: 14.6rem;
  height: .36rem;
  /*background-color: pink;*/
  margin-bottom: .25rem;

}

.card .card_title .name{
  float: left;
  width: 2.52rem;
  height: .36rem;
  color: #212121;
  margin-right: .15rem;
  font-size: .36rem;
  line-height: .36rem;
  /*margin-top: 0;*/
}


.card .card_title .lable{
  float: left;
  height: .36rem;
  /*line-height: .36rem;*/
  text-align: center;
  /*margin: auto 0;*/
}

.card .card_title .lable div{
  float: left;
  width: .94rem;
  height: .3rem;
  background-color: #f8f8f8;
  color: #b3b3b3;
  font-size: .16rem;
  margin-top: .05rem;
  line-height: .3rem;
}

.card .card_title .lable div:nth-child(1){
  /*float: left;*/
  margin-right: .1rem;
}

.card .card_title .popularity{
  float: left;
  width: 14.6rem;
  height: .16rem;
  line-height: .16rem;
  /*background-color: pink;*/
}

.card .card_title .popularity div{
  float: left;
  margin-right: .05rem;
}

.card .content{
  width: 14.6rem;
  padding-left: .8rem;
  margin-bottom: .55rem;
}

.card .content div{
  width: 13.6rem;
  height: 7.65rem;
  margin-bottom: .35rem;
  background-color: pink;
  margin-left: 1rem;
}

.card .end{
  width: 14.6rem;
  height: .4rem;
  margin: 0 auto;
  border-top: .01rem solid #c5c5c5;
  padding-top: .22rem;
  line-height: .4rem;
}

.card .end div{
  float: left;
}

.card .end div:nth-child(1){
  margin-left: .5rem;
  font-size: .16rem;
  color: #a5a5a5;
  margin-right: 5.04rem;
}

.card .end div:nth-child(2){
  height: .21rem;
  font-size: .21rem;
  color: #212121;
  margin-right: .3rem;
}

.card .end div:nth-child(3){
  font-size: .21rem;
  height: .21rem;
  color: #ec414d;
}

</style>